<template>
    <b-modal id="paywall-modal" :size="!isLoggedIn ? 'md' : 'lg'" hide-footer hide-header centered>
        <div id="paywall-panel">
            <svg
                class="position-absolute close-icon text-muted pointer"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                @click="$bvModal.hide('paywall-modal')"
            >
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
            </svg>

            <div v-if="!isLoggedIn">
                <Login />
            </div>

            <template v-else-if="showOneClickUpgrade">
                <div class="p-3">
                    <h6 class="font-weight-bold text-center mb-3">Upgrade your membership</h6>

                    <div class="p-3">
                        <OneClickUpgrade :page="entityType ? entityType : 'account'"></OneClickUpgrade>
                    </div>
                </div>
            </template>
        </div>
    </b-modal>
</template>

<script>
import { defineComponent } from 'vue'
import Login from './Login.vue'
import Errors from '../libraries/errors'
import EditPayWall from '../mixins/EditPayWall'
import { mapGetters, mapState } from 'vuex'
import Common from '../mixins/Common'
import OneClickUpgrade from './OneClickUpgrade.vue'

export default defineComponent({
    name: 'Paywall',
    components: {
        Login,
        OneClickUpgrade,
    },
    mixins: [EditPayWall, Common],
    data() {
        return {
            errors: new Errors(),
            premium_wall_event_fired: false, // this is here so we don't fire a bunch of premium wall seen events from simple shifts.
        }
    },
    computed: {
        ...mapGetters({
            isLoggedIn: 'user/isLoggedIn',
            isAdmin: 'user/isAdmin',
            getPremiumFeatures: 'document/getPremiumFeatures',
            hasSubscription: 'subscription/hasSubscription',
            isBingo: 'document/isBingo',
            isFlashcard: 'document/isFlashcard',
            isWorksheet: 'document/isWorksheet',
            entityType: 'document/entityType',
            neverHadTrial: 'user/neverHadTrial',
        }),
        ...mapState(['user', 'subscription']),
        showOneClickUpgrade() {
            return this.isLoggedIn && (this.hasSubscription || this.paywallType === 'one_click_upgrade')
        },
    },
    watch: {
        isLoggedIn(val) {
            if (val && this.neverHadTrial) {
                this.$bvModal.hide('paywall-modal')
            }
        },
    },
    methods: {
        goPremium() {
            if (!this.isLoggedIn) {
                this.$modals.logIn.is_visible = true
                this.$modals.logIn.active_tab = 'sign_up'
                this.$eventApi.create_event('paywall|not-logged-in')
            } else {
                this.$bvModal.hide('paywall-modal')
                this.$bvModal.show('checkout-modal')
            }
        },
    },
})
</script>

<style scoped>
.close-icon {
    top: 5px;
    right: 5px;
    height: 24px;
    width: 24px;
    z-index: 2;
}
</style>
